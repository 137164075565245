import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  IoHappy,
  IoTimer,
  IoShieldCheckmark,
  IoPeople,
  IoPersonCircleOutline,
  IoEye,
  IoSunny,
  IoPaperPlane,
  IoPhonePortrait,
  IoLocation,
  IoCheckmark,
  IoReceipt,
  IoArrowForward,
  IoSwapHorizontal,
  IoCalendar,
  IoFlash,
  IoPricetags,
  IoImage,
  IoCodeWorking,
  IoCloudDone,
  IoAirplane,
  IoCard,
  IoDocuments,
  IoAnalytics,
  IoHeart,
} from "react-icons/io5";
import { IoMdOpen } from "react-icons/io";

import { useModal } from "../../components/useModal";

import {
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
  Button,
} from "../../components/util";
import {
  CustomerQuote,
  Modal,
  ProductPricing,
  ImageGraphic,
  Feature,
} from "../../components/site/";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Image from "../../components/image";

import PayHeroLogo from "../../images/logos/PayHero/PayHero_Full.svg";
import FromFlexiTime from "../../images/logos/FlexiTime/FromFlexiTime_Horiz_White.svg";
import InvoxyLogo_White from "../../images/logos/Invoxy/Invoxy_Full.svg";
import DroppahLogo_Full from "../../images/logos/Droppah/Droppah_Full_Black.svg";

const CustomerName = styled.h4`
  color: #fff;
  position: absolute;
  bottom: 60px;
  left: 60px;
`;

const FeatureList = styled(Row)`
  --item-gap: 40px;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 3) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const FromFlexiTimeBadge = styled.img`
  z-index: 99;
  height: 20px;
`;

const FlexiTimeProducts = styled(Row)`
  max-width: 800px;

  > div {
    padding: 30px;
    text-align: center;
    border-radius: ${(props) => props.theme.styles.borderRadius};

    img {
      margin-bottom: 30px;
      margin-top: 30px;
      max-width: 200px;
      width: 85%;
      max-height: 35px;
    }

    h2 {
      margin: 10px 0;
    }
  }
`;

const HourlyEmployeesLP = (props) => {
  const [calendlySource, setCalendlySource] = useState("");
  const [videoModal, setVideoModal] = useState(false);
  const [itemModalOpen, setItemModalOpen] = useModal();
  const [nav, setNav] = useState(false);

  useEffect(
    function () {
      var paramsArray = props.location.search.replace("?", "").split("&");
      var paramsMap = {};

      // Create array for URL params
      for (const paramString of paramsArray) {
        const paramName = paramString.substring(0, paramString.indexOf("="));
        const paramValue = paramString.substring(paramString.indexOf("=") + 1);
        paramsMap[paramName] = paramValue;
      }

      setNav(paramsMap.nav === "true");
    },
    [props.location.search, nav]
  );

  function handleModal(url, video) {
    setItemModalOpen(true);
    setCalendlySource(url);
    setVideoModal(video);
  }

  const benefits = [
    "Accurately pay variable hour employees",
    "Track to-the-minute timesheets & breaks",
    "Options for mobile timesheets, GPS or photo time clock",
    "Compliant leave calculations, even when work patterns change",
    "Understand wage costs by job or department",
    "Add integrated rostering, job management or contractor management",
  ];

  const features = [
    {
      icon: <IoCalendar />,
      title: "Leave requests & approvals",
    },
    {
      icon: <IoFlash />,
      title: "Automated payday filing",
    },
    {
      icon: <IoPricetags />,
      title: "Expense reimbursements",
    },
    {
      icon: <IoImage />,
      title: "Employer branded payslips",
    },
    {
      icon: <IoSwapHorizontal />,
      title: "Automated paid & unpaid break compliance",
    },
    {
      icon: <IoCodeWorking />,
      title: "Deep Xero integration & cost tracking",
    },
    {
      icon: <IoCloudDone />,
      title: "Cloud-based and always up-to-date",
    },
    {
      icon: <IoAirplane />,
      title: "Put your payroll on AutoPilot",
    },
    {
      icon: <IoCard />,
      title: "Easy payments with POLi",
    },
    {
      icon: <IoDocuments />,
      title: "Document management",
    },
    {
      icon: <IoAnalytics />,
      title: "Insights reporting",
    },
    {
      icon: <IoHeart />,
      title: "Expert NZ-based support",
    },
  ];

  return (
    <Layout fullPage={!nav}>
      <Seo
        title="Online Payroll Software for Hourly Workers | PayHero"
        description="Take care of your team on payday with smart, modern and seriously compliant online payroll for NZ businesses. Get started today."
        pathname={props.location.pathname}
      />
      {!nav && (
        <Container css={{ position: "sticky", top: "0", zIndex: "999" }}>
          <Wrapper stackGap={10}>
            <Row alignCenter noBreak>
              <img
                src={PayHeroLogo}
                alt="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
                css={{ maxWidth: "220px", maxHeight: "30px" }}
              />
              <FlexButtons className="hideOnMobile">
                <Button className="primary -md gtm-cta" to="/signup">
                  Try 14 Days Free
                </Button>
                <Button
                  className="grey -md gtm-cta"
                  to="#!"
                  onClick={() => {
                    handleModal("https://calendly.com/d/ynv-22p-2r5", false);
                  }}
                  atag
                >
                  Book a Sales Call
                </Button>
              </FlexButtons>
              <div className="showOnMobile">
                <Button className="primary -md gtm-cta" to="/signup">
                  Try 14 Days Free
                </Button>
              </div>
            </Row>
          </Wrapper>
        </Container>
      )}
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h1>
                  Integrated payroll & time tracking{" "}
                  <span className="-fontPrimary">for hourly employees</span>
                </h1>
                <h3 className="-fontNormal" css={{ color: "#333" }}>
                  Track hourly work with ease and automatically calculate pay
                  and entitlements for part time, casual, contract or shift
                  workers.
                </h3>
              </Box>
              <Box stackGap={15}>
                {benefits.map((item, i) => {
                  return (
                    <Row
                      alignCenter
                      noBreak
                      stackGap={10}
                      justify="flex-start"
                      key={i}
                      css={{ fontSize: "1.2rem", textAlign: "left" }}
                      centerOnMobile
                    >
                      <IoCheckmark
                        css={{
                          fontSize: "1.8rem",
                          color: "#5eb22e",
                          flex: "0 0 auto",
                        }}
                      />
                      <span>{item}</span>
                    </Row>
                  );
                })}
              </Box>
              <Box stackGap={20}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    to="#!"
                    onClick={() => {
                      handleModal("https://calendly.com/d/ynv-22p-2r5", false);
                    }}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
              </Box>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="Hospo_Hero.png"
                alt="Payroll for Hospitality"
                centerImage
                addShadow
                rounded
              />
              <CustomerName>
                <b className="-handWritten">Panhead Custom Ales,</b>
                <br /> <span css={{ fontSize: "1rem" }}>PayHero Customer</span>
              </CustomerName>
            </Box>
          </Row>
          <Image
            filename="Hourly_Customer_Logos.png"
            alt="PayHero Hourly Employee Customers"
            centerImage
          />
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={120} noPaddingBottom>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Sync timesheets from mobile directly to payroll"
                filename="PayHero_Review_Hospo.png"
                addShadow
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Stress-free payroll
                </h2>
                <h4 className="h2">The easy way to pay non-salaried staff</h4>
              </Box>
              <Feature
                icon={<IoHappy key={0} />}
                title="Fast, accurate and stress-free"
                description="Pay your staff in minutes and let our powerful payroll software do the hard work for you. Take care of all your wage and leave calculations, payslips and payday filing in a few clicks."
              />
              <Feature
                icon={<IoPaperPlane key={0} />}
                title="Online timesheets sent straight to payroll"
                description="Don’t waste time on paper timesheets and data entry. Timesheets from the employee mobile app, GPS tracking or the photo time clock app flow directly into payroll, saving time and increasing accuracy."
              />
              <Feature
                icon={<IoShieldCheckmark key={0} />}
                title="Built for compliance"
                description="Designed to simplify payroll for part time & variable hour staff and those with changing work patterns, PayHero follows the latest MBIE Holidays Act guidelines to ensure your employees maintain the right holiday entitlements."
              />
              <Feature
                icon={<IoSunny key={0} />}
                title="Automated public holiday calculations"
                description="PayHero determines whether a public holiday is an otherwise working day for an employee based on their recent work pattern and calculates their entitlements accordingly."
              />
            </Box>
          </Row>
          <FeatureList justify="center" isWrap>
            {features.map((item, i) => {
              return <Feature key={i} icon={item.icon} title={item.title} />;
            })}
          </FeatureList>
          <CustomerQuote
            quote="The only software on the market currently able to accurately calculate the leave entitlements for employees with irregular working patterns, without manual intervention, is PayHero."
            name="Irene Bennetts"
            company="Admin Army"
            pic="Irene_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      {/* <Container>
        <Wrapper noPaddingBottom stackGap={120}>
          <FeatureList justify="center" isWrap>
            {features.map((item, i) => {
              return <Feature key={i} icon={item.icon} title={item.title} />;
            })}
          </FeatureList>
          <hr />
        </Wrapper>
      </Container> */}
      <Container>
        <Wrapper stackGap={120}>
          <Row stackGap={100} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Simple, accurate time tracking
                </h2>
                <h4 className="h2">
                  Record exact clock in and out times for fast, error-free
                  payroll
                </h4>
              </Box>
              <Feature
                icon={<IoPersonCircleOutline key={0} />}
                title="Start shifts with a selfie"
                description="Get your staff to clock in and out and record breaks with our photo time clock app. It's easy and fun for employees to use, increases your payroll accuracy and saves hours of admin time."
              />
              <Feature
                icon={<IoTimer key={0} />}
                title="Automated timesheets"
                description="Clock in and outs automatically update timesheets in your payroll. No more collecting paper timesheets, checking the hours have been added up correctly and typing them into your payroll system."
              />
              <Feature
                icon={<IoEye key={0} />}
                title="Real-time attendance"
                description="Monitor the day’s attendance & breaks in real-time. Keep a tab on who’s on the clock, taking a break, running late and working overtime."
              />
            </Box>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Clock In & Out | Photo Time Clock | PayHero"
                filename="PayHeroShift_LiveAttendance.png"
                maxWidth={600}
                addShadow
                centerImage
                rounded
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="The photo time clock creates a sense of fairness by accurately recording hours, so both the staff and the owner know they’re not being taken advantage of."
            name="Paul Newson"
            company="Brazen Clothing"
            pic="Brazen_Circle.png"
            large
            center
          />
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Timesheets, leave, expenses & payslips on mobile"
                filename="PayHero_Mobile_Tradie.png"
                maxWidth={500}
                addShadow
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Employee mobile app
                </h2>
                <h4 className="h2">
                  GPS time tracking, expenses, leave and payslips in one simple
                  employee app
                </h4>
              </Box>
              <Feature
                icon={<IoPhonePortrait key={0} />}
                title="Timesheets in their pocket"
                description="Employees can submit timesheets from anywhere by entering start, end and break times. They can access their payslips, request leave and submit expense claims too."
              />
              <Feature
                icon={<IoLocation key={0} />}
                title="GPS time tracking"
                description="GPS clocking lets you know where your on-the-go and field staff are working throughout the day. Clock in & out records on the PayHero mobile app will show you employee locations as they start and finish jobs."
              />
              <Feature
                icon={<IoPeople key={0} />}
                title="Team manager clock-in"
                description="If you’ve got teams of people working at different locations your site managers can clock employees in & out of work sites from their phone."
              />
              <Feature
                icon={<IoReceipt key={0} />}
                title="Easy job costing"
                description="With the PayHero employee app your staff can allocate hours against different departments, locations or tasks. That means you can easily see the profitability of each job or team, get your invoicing spot on and apply wage costs to the right cost centre in Xero."
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="We love the employee mobile app. It means we can all record time and keep track of our hours from wherever we’re working."
            name="Miranda Hitchings"
            company="Dignity"
            pic="Dignity_Circle.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2>Pricing</h2>
              <h3 css={{ color: "#333" }} className="-fontNormal">
                No Hidden Costs · Easy Set Up · Cancel Anytime
              </h3>
            </div>
            <ProductPricing selectedProduct="payhero" hideAddOns hideCTAs />
            <p className="-textCenter" css={{ color: "#999" }}>
              Prices are in NZD and exclude GST.
            </p>
          </Box>
          {/* <CustomerQuote
            quote="Everyone is excited about the move to PayHero, with overwhelmingly positive feedback across the brewery. You’ve got a fantastic product there."
            name="Ben Bakker"
            company="Panhead"
            pic="Panhead_Circle.png"
            large
            center
          /> */}
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2>Other products from FlexiTime</h2>
              <h3 css={{ color: "#333" }} className="-fontNormal">
                Get more out of PayHero with deeply integrated rostering and
                billing software.
              </h3>
            </div>
            <FlexiTimeProducts className="-center" stackGap={30}>
              <Box size={50} stackGap={40} className="droppah-primary">
                <div>
                  <span className="-fontBold">Rostering</span>
                  <img
                    className="-center"
                    src={DroppahLogo_Full}
                    alt="Droppah | Scheduling, timesheet & attendance software"
                  />
                  <h4>
                    Roster your staff and track time and attendance to control
                    costs & optimise staff coverage.
                  </h4>
                </div>
                <Box stackGap={30}>
                  <a
                    className="link-floating -center"
                    href="https://www.droppah.com"
                    target="_blank"
                  >
                    Rostering <IoMdOpen css={{ top: "2px" }} />
                  </a>
                </Box>
              </Box>
              <Box size={50} stackGap={40} className="invoxy-primary">
                <div>
                  <span className="-fontBold">Contractor Management</span>
                  <img
                    className="-center"
                    src={InvoxyLogo_White}
                    alt="Karmly | Contractor Management software"
                  />
                  <h4>
                    Increase billing accuracy and get paid faster with
                    timesheets, approvals & batch invoicing.
                  </h4>
                </div>
                <Box stackGap={30}>
                  <a
                    className="link-floating -center"
                    href="https://www.karmly.com/recruitment"
                    target="_blank"
                  >
                    Contractor Management <IoMdOpen css={{ top: "2px" }} />
                  </a>
                </Box>
              </Box>
            </FlexiTimeProducts>
          </Box>
        </Wrapper>
      </Container>
      <Container className="secondary">
        <Wrapper>
          <Box stackGap={40}>
            <Box stackGap={7} className="-textCenter">
              <h2 className="h1">
                Manage complex payroll{" "}
                <span className="-fontPrimary">& pay employees right</span>
              </h2>
              <h3 className="-fontNormal">
                Talk to the team to see how PayHero can help your business.
              </h3>
            </Box>
            <FlexButtons justify="center">
              <Button className="primary -lg gtm-cta" to="/signup">
                Try 14 Days Free
              </Button>
              <Button
                className="dark -lg gtm-cta"
                to="#!"
                onClick={() => {
                  handleModal("https://calendly.com/d/ynv-22p-2r5", false);
                }}
                atag
              >
                Book a Sales Call
              </Button>
            </FlexButtons>
          </Box>
          <FromFlexiTimeBadge className="-center" src={FromFlexiTime} />
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL={calendlySource}
        embedTitle="Introducing PayHero | Video"
        handleClose={() => setItemModalOpen(false)}
        isVideo={videoModal}
      />
    </Layout>
  );
};

export default HourlyEmployeesLP;
